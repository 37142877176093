<style>
	.param-line-chart .two-border {
		background: linear-gradient(#79f7f1, #79f7f1) right top,
			linear-gradient(#79f7f1, #79f7f1) right top,
			linear-gradient(#79f7f1, #79f7f1) right bottom,
			linear-gradient(#79f7f1, #79f7f1) right bottom;
		background-repeat: no-repeat;
		background-size: 2px 10px, 10px 2px;
		padding: 2px;
	}
</style>
<template>
	<div class="param-line-chart">
		<h4 style="line-height:34px"><span :style="'margin-right:12px;color:'+color"><i :class="icon" style="font-size:16px;"></i> {{title}}</span> <img src="./paramLineChart/title.png" /> <span style="color:rgba(255,255,255,0.45);font-size:12px;float:right;line-height:44px">{{dt}}</span></h4>
		<a-row style="border:1px solid #012E32;border-radius:2px">
			<a-col span="15" style="padding:10px 10px 0 10px">
				<div :id="container" style="height:74px;margin-bottom:5px"></div>
				<h6 :style="'color:'+color+'AF;font-weight:300;'">Max {{data_max}} <span style="float:right">Min {{data_min}}</span></h6>
			</a-col>
			<a-col span="9" style="height:120px">
				<div style="background:#012B30;height:120px;padding:6px">
					<div class="two-border">
						<h1 :style="'color:'+color+';text-align:center;width:100%;font-size:30px;margin-bottom:0px;padding-top:20px'">{{data_now_val}}</h1>
						<h6 :style="'color:'+color+';text-align:center;width:100%;padding-bottom:15px'">{{unit}}</h6>
					</div>
				</div>
			</a-col>
		</a-row>
		<h6 :style="'line-height:34px;color:'+color+'99'">比上周
			<span v-if="d>0">{{d}}{{unit}}↑</span>
			<span v-if="d<0">{{d*(-1)}}{{unit}}↓</span>
			<span v-if="d==0">不变</span>
			<span style="float:right" v-if="per">异常占比{{per}}%</span>
		</h6>
	</div>
</template>
<script>
	import { TinyLine } from "@antv/g2plot";
	export default {
		name: "paramLineChart",
		props: [
			"container", //折线容器id
			"icon", //标题图标
			"title", //标题文字
			"color", //主题色
			"unit", //单位
			"list", //折线数据(一维数组)
			"dt", //更新时间
			"per", //异常比例
			"d", //比上周
		],
		data() {
			return {
				lineChart: null,
			};
		},
		watch: {
			list(val) {
				const d = this.list;
				this.lineChart.update({
					yAxis: {
						min: this.data_min,
					},
					data: d,
				});
			},
		},
		computed: {
			data_max() {
				let d = this.list;
				let max = 0;
				for (let i = 0; i < d.length; i++) {
					if (i == 0 || max < d[i]) {
						max = d[i];
					}
				}
				return max;
			},
			data_min() {
				let d = this.list;
				let min = 0;
				for (let i = 0; i < d.length; i++) {
					if (i == 0 || min > d[i]) {
						min = d[i];
					}
				}
				return min;
			},
			data_now_val() {
				let d = this.list;
				if (d.length > 1) {
					return d[d.length - 1];
				} else {
					return 0;
				}
			},
		},
		mounted() {
			this.lineChart = new TinyLine(this.container, {
				lineStyle: {
					lineWidth: 2,
				},
				color: this.color,
				animation: {
					appear: {
						easing: "easeLinear",
						duration: 3000,
						delay: 0,
					},
				},
				data: [],
			});
			this.lineChart.render();
		},
	};
</script>