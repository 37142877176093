<style>

    .deviceItem {
        width: 31%;
        border: 1px solid rgba(0, 238, 251, 0.35);
        margin: 0 3px;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 5px;
        font-size: 12px;
        color: rgba(255, 255, 255, 0.65);
    }

    .dicheck {
        background: rgba(39, 125, 136, 0.35);
        color: #79f7f1;
    }

    .left {
        width: 380px;
    }

    .center {
        width: calc(100% - 380px - 380px);
    }


    .deviceItem img {
        padding-top: 10px;
        width: 90px;
        height: 90px;
    }

    .lr {
        display: flex;
        align-items: center;
    }

    .bitem {

        height: 130px;
        width: 90px;
        margin-bottom: 10px;
        margin-right: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 10px;
        justify-content: space-between;
        background: rgba(2, 32, 36, 0.65);
        font-size: 12px;
        border: 1px solid rgba(46, 62, 70, 1);
    }

    .ant-layout-header {
        background: black;
    }

    .bitem_1 img {
        border-radius: 25px;
    }

    .bitem_2 {
        width: 100%;
        color: rgba(121, 247, 241, 1);
        text-align: center;
        line-height: 28px;
        font-size: 12px;
    }

    .bitem_3 {
        background: rgba(26, 68, 68, 1);
        width: 100%;
        color: white;
        text-align: center;
        line-height: 28px;
        font-size: 12px;
    }

    .rank {
        border-radius: 3px;
        float: left;
        width: 108px;
        margin-right: 9px;
        text-align: center;


    }

    .rank .icon {
        margin-top: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 48px;
        width: 48px;
        height: 48px;
        margin-left: calc((108px - 48px) / 2);
    }

    .rank .title {
        text-align: center;
        line-height: 30px;
    }

    .rank .status {
        background: #35603b;
        color: #fff;
        text-align: center;
        line-height: 30px;
        height: 30px;
        margin: 0;
    }

    .rank.n0 {
        border: 1px solid #7ffb83;

    }

    .rank.n0 .icon {
        background: #1f372a;
        color: #9ff890;
    }

    .rank.n0 .title {
        color: #7ffb83;
    }

    .rank.n0 .status {
        background: #35603b;
    }

    .rank.n1 {
        border: 1px solid #79f7f1;
    }

    .rank.n1 .icon {
        background: #1a4445;
        color: #79f7f1;
    }

    .rank.n1 .title {
        color: #79f7f1;
    }

    .rank.n1 .status {
        background: #1a4444;
    }

    .right {
        width: 380px;
        display: flex;
        flex-direction: column;
    }

    .sleep_info {
        margin-left: 15px;
        font-size: 10px !important;
    }

    .sleep_time {
        color: #FFFFFF;
        opacity: 0.65;
        margin-left: 10px
    }
</style>
<template>

    <div class="user-health-more" style="background-color: #000c17;height: 100vh">

        <video-bg id="bg"  style="height: calc(100vh - 69px)">
            <div style="height: 10px">1</div>
            <a-layout-header>
                <img src="./index/userHealth/title.png" style="width: 100%;position: relative;"/>
            </a-layout-header>
            <div style="display: flex;">
                <div class="left">
                    <techCard title="用户基本信息">
                        <a-row>
                            <a-col span="8">
                                <img src="./index/userHealth/photo.png" height="119" width="110"/>
                            </a-col>
                            <a-col style="padding-left: 20px" span="15">
                                <h6 style="border-bottom:1px solid #79F7F1;padding-bottom:5px;margin-bottom:10px;">
                                    <span style="color:#79F7F1;padding-right:15px">ID编号</span> <span
                                        style="color:#FEB433">{{user_info.id}}</span></h6>
                                <a-row :gutter="10">
                                    <a-col span="8">
                                        <h6 style="color:#79F7F1;line-height:24px">
                                            姓名
                                            <span style="color:#ffffff99">
													{{user_info.name}}
												</span>
                                        </h6>
                                    </a-col>
                                    <a-col span="16">
                                        <h6 style="color:#79F7F1;line-height:24px">
                                            出生年月
                                            <span style="color:#ffffff99">
													{{user_info.birth}}
												</span>
                                        </h6>
                                    </a-col>
                                    <a-col span="8">
                                        <h6 style="color:#79F7F1;line-height:24px">
                                            性别
                                            <span style="color:#ffffff99">
													{{user_info.sex==1?'男':''}}
													{{user_info.sex==2?'女':''}}
												</span>
                                        </h6>
                                    </a-col>
                                    <a-col span="16">
                                        <h6 style="color:#79F7F1;line-height:24px">
                                            体重
                                            <span style="color:#ffffff99">
													{{user_info.weight}} 公斤
												</span>
                                        </h6>
                                    </a-col>
                                    <a-col span="12">
                                        <h6 style="color:#79F7F1;line-height:24px">
                                            身高
                                            <span style="color:#ffffff99">
													{{user_info.height}} 公分
												</span>
                                        </h6>
                                    </a-col>
                                </a-row>
                            </a-col>
                        </a-row>

                        <div style="display: flex;flex-direction: row;color: white;margin-top:22px;margin-bottom: 10px">
                            <div class="lr">
                                <img style="width: 15px" src="/video/left.png">
                            </div>

                            <div class="deviceItem " :class="item.use=='check'?'dicheck':''"
                                 v-for="item in user_device">
                                <div>
                                    <img :src="item.img" height="110" width="110"/>
                                </div>
                                <div style="text-align: center;padding: 5px 10px;margin-bottom: 10px">
                                    {{item.name}}
                                </div>
                            </div>

                            <div class="lr">
                                <img style="width: 15px" src="/video/right.png">
                            </div>
                        </div>
                    </techCard>
                    <tech-card title="近30次血压">
                        <!---近30次血压-->
                        <div id="xueya" style="height: 17vh;margin-top: -15px">

                        </div>

                    </tech-card>
                    <tech-card title="近30次血糖" s>
                        <!---近30次血糖-->
                        <div id="xuetang" style="height: 17vh;margin-top: -10px">

                        </div>

                    </tech-card>


                </div>
                <div class="center"
                     style="display: flex;flex-direction:column;justify-content: space-between;">
                    <div style="display: flex;justify-content: space-between;margin:0 20px;">
                        <div style="width: 260px">
                            <paramLineChart key="c1" title="心率" container="hr" icon="iconfont icon-xinshuai"
                                            color="#79F7F1" unit="bpm" :list="realtime_info.hr"
                                            :dt="realtime_info.hr_dt" :per="realtime_info.hr_per"
                                            :d="realtime_info.hr_d"/>
                            <paramLineChart key="c2" title="血氧" container="ox" icon="iconfont icon-xieyang"
                                            color="#9CEB36" unit="%" :list="realtime_info.ox" :dt="realtime_info.ox_dt"
                                            :per="realtime_info.ox_per" :d="realtime_info.ox_d"/>
                            <paramLineChart key="c3" title="血糖" container="bs" icon="iconfont icon-xietang"
                                            color="#FE4338" unit="mmol/L" :list="realtime_info.bs"
                                            :dt="realtime_info.bs_dt" :per="realtime_info.bs_per"
                                            :d="realtime_info.bs_d"/>
                        </div>
                        <div style="width: 260px">
                            <paramLineChart key="c4" title="收缩压" container="bps" icon="iconfont icon-xueya"
                                            color="#69E3BF" unit="mmHg" :list="realtime_info.bps"
                                            :dt="realtime_info.bps_dt" :per="realtime_info.bps_per"
                                            :d="realtime_info.bps_d"/>
                            <paramLineChart key="c5" title="舒张压" container="bpd" icon="iconfont icon-xueya"
                                            color="#559CF1" unit="mmHg" :list="realtime_info.bpd"
                                            :dt="realtime_info.bpd_dt" :per="realtime_info.bpd_per"
                                            :d="realtime_info.bpd_d"/>
                            <paramLineChart key="c6" title="体脂率" container="bmi" icon="iconfont icon-xueya"
                                            color="#FEB433" unit="mmHg" :list="realtime_info.bmi"
                                            :dt="realtime_info.bmi_dt" :per="realtime_info.bmi_per"
                                            :d="realtime_info.bmi_d"/>
                        </div>
                    </div>
                    <div style="position: absolute;bottom: 108px;">
                        <techCard title="风险红绿灯" style="height: 128px;margin: 10px 10px 10px 10px;">
                            <div style="width: calc(100vw - 410px - 400px);display: flex;align-items: center;justify-content: center;margin-top: -5px">

                                <div :class=" item.status=='0'?' rank n0 ':' rank n1 '" v-for="item in hld">
                                    <div class="icon">
                                        <i class="iconfont " :class="item.class" style="font-size:29px"></i>
                                    </div>
                                    <h6 class="title">
                                        {{item.name}}</h6>
                                    <h6 class="status">{{item.status=='0'?'正常':'轻微风险'}}</h6>
                                </div>

                            </div>
                        </techCard>
                    </div>
                </div>
                <div class="right">
                    <techCard title="运动分析" style="height: 28vh;color: white">
                        <div style="display: flex">
                            <div style="height: 100%;width: 50%">
                                <div id="yundong" style="height:20vh;">
                                    <div id="sport" style="height: 20vh;"></div>
                                </div>
                            </div>
                            <div style="height:20vh;width: 50%;">
                                <div id="sport_col" style="height: 20vh;"></div>
                                <div style="color:#79F7F1;position: absolute;font-size: 12px;margin-top: -20px;margin-left: 50px">
                                    热量 <span
                                        style="color:#ffffffaa">{{userRight2Data.sport.cal}}</span>
                                </div>
                            </div>
                        </div>
                    </techCard>
                    <techCard title="睡眠分析" style="height: 29vh;margin-top: 10px">
                        <div style="display: flex">
                            <div style="width: 50%">
                                <div id="shuimian" style="height:20vh;">1</div>
                            </div>
                            <div style="height:20vh;width: 50%;color: #00feff">
                                <div style="display: flex;justify-content: space-around;line-height: 30px">
                                    <div>{{userRight2Data.sleep.start}}</div>
                                    <div>{{userRight2Data.sleep.end}}</div>
                                </div>
                                <div style="display: flex;width: 80%;margin-left: 10%;height: 25px;margin-top: 10px;margin-bottom: 10px">
                                    <div v-for="item in userRight2Data.sleep.list"
                                         :style="'height: 25px;width:'+ item.width+'%;background-color:#'+ item.color+''">
                                    </div>
                                </div>
                                <!--                                '1-清醒期 2-快速眼动 3-浅睡期 4-深睡期'-->
                                <div style="margin-top: 20px">
                                    <div style="display: flex;align-items: center;margin-left: 25px">
                                        <div style="background-color: #5B8FF9;height:10px;width: 10px; "></div>
                                        <div class="sleep_info">深睡</div>
                                        <div class="sleep_time">{{userRight2Data.sleep.light}}</div>
                                    </div>
                                    <div style="display: flex;align-items: center;margin-left: 25px">
                                        <div style="background-color: #79F7F1;height:10px;width: 10px "></div>
                                        <div class="sleep_info">浅睡</div>
                                        <div class="sleep_time">{{userRight2Data.sleep.deep}}</div>
                                    </div>
                                    <div style="display: flex;align-items: center;margin-left: 25px">
                                        <div style="background-color: #FDFE66;height:10px;width: 10px "></div>
                                        <div class="sleep_info">清醒</div>
                                        <div class="sleep_time">{{userRight2Data.sleep.wake}}</div>
                                    </div>
                                    <div style="display: flex;align-items: center;margin-left: 25px">
                                        <div style="background-color:#C3E4FD;height:10px;width: 10px "></div>
                                        <div class="sleep_info">REM</div>
                                        <div class="sleep_time">{{userRight2Data.sleep.rem}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </techCard>
                    <techCard title="体重趋势分析" style="height: 29vh;margin-top: 10px;margin-left: 1px">
                        <div id="tz" style="height: 20vh;margin-top: 10px">

                        </div>
                    </techCard>
                </div>
            </div>
        </video-bg>
    </div>

</template>
<script>
    import * as echarts from "echarts";
    import VideoBg from "vue-videobg";
    import techCard from "../../components/data/techCard.vue";
    import paramLineChart from "../../components/data/paramLineChart.vue";
    import request from "../../plugins/axios";
    import {TinyColumn} from "@antv/g2plot";
    import {RingProgress} from "@antv/g2plot";
    import {Area} from "@antv/g2plot";

    export default {
        components: {
            VideoBg,
            techCard, //科技卡片
            paramLineChart, //生理指标卡片
        },
        data() {
            return {
                user_info: '',
                user_device: [],
                userRight2Data: {
                    sport: {},
                    sleep: []
                },


                sportTinyColumn: null,
                tips: "",
                base_info: {
                    user: {},
                    user_bp_list: [],
                    user_bp_info: {},
                    user_bs_list: [],
                    user_bs_info: {},
                    sport: {},
                    sleep: {},
                    weight: {},
                    cur_bmi: 0,
                    cur_weight: 0,
                    cur_fat: 0,
                },
                realtime_info: {
                    hr: [],
                    hr_dt: "",
                    bt: [],
                    ox_dt: "",
                    ox: [],
                    bpd_dt: "",
                    bpd: [],
                    bps_dt: "",
                    bps: [],
                    bt_dt: "",
                    bs: [],
                    bs_dt: "",
                    bmi: [],
                    bmi_dt: "",
                    bps_per: '',
                    bps_d: ''
                },
                user_weight: [],
                hld: []
            };
        },
        created() {

        },
        mounted() {
            let that = this
            that.getData();
            /*  setInterval(function () {
                  that.getData();
              }, 10000)

              setInterval(function () {
                  location.href = location.href
              }, 100000)*/


        },
        methods: {
            getData() {
                let that = this
                that.xueya();
                that.get_user();
                that.userCenter();
                that.userRight();
                that.userRight2();
                that.main_icase();
            },
            main_icase() {
                let that = this
                let uid = localStorage.getItem("userInfo")
                request({
                    url: "/hospital/News/main_icase",
                    data: {
                        uid: uid
                    },
                }).then((ret) => {
                    that.hld = ret.data
                });
            },
            //情绪
            qingxu() {
                this.category('qingxu', '情绪分析', [], [0, 0, 0, 0])
            },

            //30次血压和血糖
            xueya() {
                let that = this
                let uid = localStorage.getItem("userInfo")
                request({
                    url: "/hospital/News/bpt",
                    data: {
                        uid: uid
                    },
                }).then((ret) => {
                    if (ret.code == 1) {
                        that.category('xuetang', '', ret.data.add_time2, ret.data.bs, '血糖')
                        that.categoryT('xueya', '', ret.data.add_time, ret.data.Diastolic, ret.data.Systolic)
                    }
                });

            },
            //曲线图
            category(id, name, x, y, yname) {
                let birth = echarts.init(document.getElementById(id));
                let option = {
                    textStyle: {
                        color: '#666666'
                    },
                    title: {
                        text: name,//标题
                        textStyle: {
                            color: '#79F7F1',
                            fontWeight: 'normal'
                        }
                    },
                    tooltip: {
                        trigger: 'axis',

                    },
                    legend: {
                        data: []
                    },

                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        top: '10',
                        containLabel: true

                    },
                    xAxis: [
                        {
                            type: 'category',
                            boundaryGap: false,
                            data: x
                        }
                    ],
                    yAxis: [
                        {
                            type: 'value',
                            splitLine: {
                                // 网格线
                                show: true,
                                lineStyle: { //分割线
                                    color: "rgba(255,244,222,0.1)",
                                    width: 1,
                                    type: "dotted" //dotted：虚线 solid:实线
                                }
                            }
                        }
                    ],
                    series: [
                        {
                            name: yname,
                            type: 'line',
                            stack: 'Total',
                            smooth: true,
                            itemStyle: {
                                normal: {
                                    color: '#79F7F1',
                                    lineStyle: {
                                        color: '#79f7f1' //改变折线颜色
                                    }
                                },

                            },
                            lineStyle: {
                                width: 0,
                                color: '#666'
                            },
                            showSymbol: true,
                            //背景色透明度设置
                            areaStyle: {
                                opacity: 0.6,
                                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                    {
                                        offset: 0,
                                        color: "rgba(105,227,191,0.85)",
                                    },
                                    {
                                        offset: 1,
                                        color: "rgba(7,52,143,0.1)",
                                    }
                                ])
                            },
                            emphasis: {
                                focus: 'series'
                            },
                            //y轴高度
                            data: y
                        },
                    ]
                };
                birth.setOption(option);
            },
            //曲线图
            categoryT(id, name, x, y, y2) {
                let birth = echarts.init(document.getElementById(id));
                let option = {
                    textStyle: {
                        color: '#666666'
                    },
                    title: {
                        text: name,//标题
                        textStyle: {
                            color: '#79F7F1',
                            fontWeight: 'normal'
                        }
                    },
                    tooltip: {
                        trigger: 'axis'
                    },
                    legend: {
                        data: ['Email', 'Union Ads']
                    },

                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        top: '10',
                        containLabel: true

                    },
                    xAxis: [
                        {
                            type: 'category',
                            boundaryGap: false,
                            data: x
                        }
                    ],
                    yAxis: [
                        {
                            type: 'value',
                            splitLine: {
                                // 网格线
                                show: true,
                                lineStyle: { //分割线
                                    color: "rgba(255,244,222,0.1)",
                                    width: 1,
                                    type: "dotted" //dotted：虚线 solid:实线
                                }
                            }
                        }
                    ],
                    series: [

                        {
                            name: '舒张压',
                            type: 'line',
                            stack: 'Total',
                            smooth: true,
                            itemStyle: {
                                normal: {
                                    color: '#79f7f1',
                                    lineStyle: {
                                        color: '#559CF1 ' //改变折线颜色

                                    }
                                },

                            },
                            lineStyle: {
                                width: 0,
                                color: '#666'
                            },
                            showSymbol: true,
                            //背景色透明度设置
                            areaStyle: {
                                opacity: 0.6,
                                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                    {
                                        offset: 0,
                                        color: "rgba(105,227,191,0.85)",
                                    },
                                    {
                                        offset: 1,
                                        color: "rgba(7,52,143,0.1)",
                                    }
                                ])
                            },
                            emphasis: {
                                focus: 'series'
                            },
                            //y轴高度
                            data: y2
                        },
                        {
                            name: '收缩压',
                            type: 'line',
                            stack: 'Total',
                            smooth: true,
                            itemStyle: {
                                normal: {
                                    color: '#559CF1',
                                    lineStyle: {
                                        color: '#79f7f1' //改变折线颜色
                                    }
                                },

                            },
                            lineStyle: {
                                width: 0,
                                color: '#666'
                            },
                            showSymbol: true,
                            //背景色透明度设置
                            areaStyle: {
                                opacity: 0.6,
                                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                    {
                                        offset: 0,
                                        color: "rgba(85,156,241,0.85)",

                                    },
                                    {
                                        offset: 1,
                                        color: "rgba(7,52,143,0.1)",
                                    }
                                ])
                            },
                            emphasis: {
                                focus: 'series'
                            },
                            //y轴高度
                            data: y
                        }
                    ]
                };
                birth.setOption(option);
            },
            //柱状图
            bar(id, name, x, y) {
                let birth = echarts.init(document.getElementById(id));
                let option = {
                    xAxis: {
                        type: 'category',
                        data: [],
                        axisTick: {show: false},   // 不显示坐标轴刻度线
                        axisLine: {show: false},      // 显示坐标轴线
                        splitLine: {show: false},    // 不显示分隔线
                        axisLabel: {show: false}    // 不显示坐标轴标签
                    },
                    yAxis: {
                        type: 'value',
                        axisTick: {show: false},   // 不显示坐标轴刻度线
                        axisLine: {show: false},      // 显示坐标轴线
                        splitLine: {show: false},    // 不显示分隔线
                        axisLabel: {show: false}    // 不显示坐标轴标签
                    },
                    grid: {
                        top: 0,
                        bottom: 40
                    },
                    series: [
                        {
                            data: y,
                            type: 'bar',
                            itemStyle: {
                                normal: {
                                    barBorderRadius: [30, 30, 30, 100],
                                    color: '#79F7F1',  // 将柱子颜色改为绿色
                                }
                            }

                        }
                    ]
                };
                birth.setOption(option);
            },
            //饼图
            pie(id, name, detail, color) {
                let birth = echarts.init(document.getElementById(id));
                let option = {
                    tooltip: {
                        trigger: 'item'
                    },
                    legend: {
                        top: '5%',
                        left: 'center'
                    },
                    color: color,
                    series: [
                        {
                            type: 'pie',
                            radius: ['75%', '60%'],
                            avoidLabelOverlap: false,
                            itemStyle: {
                                borderColor: '#fff',
                                borderWidth: 0
                            },
                            silent: true,
                            label: {
                                normal: {
                                    show: true,
                                    position: 'center',
                                    color: '#79F7F1',
                                    formatter: '{total|' + detail.total + '}' + '\n\r' + '{active|步}' + '\n\r' + detail.eval,
                                    rich: {
                                        total: {
                                            fontSize: 16,
                                            fontFamily: "微软雅黑",
                                            color: '#79F7F1'
                                        },
                                        active: {
                                            fontFamily: "微软雅黑",
                                            fontSize: 12,
                                            color: '#fff',
                                            lineHeight: 30,
                                        },
                                    }
                                },
                                emphasis: {//中间文字显示
                                    show: true,
                                }
                            },
                            emphasis: {
                                label: {
                                    show: true,
                                    fontSize: 40,
                                    fontWeight: 'bold'
                                }
                            },
                            labelLine: {
                                show: false
                            },
                            data: [
                                {value: detail.per, name: ''},
                                {value: (100 - detail.per), name: ''},

                            ],

                        }
                    ]
                };
                birth.setOption(option);
            },
            pie2(id, name, detail, color) {
                let birth = echarts.init(document.getElementById(id));
                let option = {
                    tooltip: {
                        trigger: 'item'
                    },
                    legend: {
                        top: '5%',
                        left: 'center'
                    },
                    color: color,
                    series: [
                        {
                            type: 'pie',
                            radius: ['75%', '60%'],
                            avoidLabelOverlap: false,
                            itemStyle: {
                                borderColor: '#fff',
                                borderWidth: 0
                            },
                            label: {
                                normal: {
                                    show: true,
                                    position: 'center',
                                    color: '#79F7F1',
                                    formatter: '{total|' + detail.h + '}小时' + detail.m + '分' + '\n\r' + '{active|' + detail.eval + '}',
                                    rich: {
                                        total: {
                                            fontSize: 16,
                                            fontFamily: "微软雅黑",
                                            color: '#79F7F1'
                                        },
                                        active: {
                                            fontFamily: "微软雅黑",
                                            fontSize: 12,
                                            color: '#fff',
                                            lineHeight: 30,
                                        },
                                    }
                                },
                                emphasis: {//中间文字显示
                                    show: true,
                                }
                            },
                            silent: true,
                            labelLine: {
                                show: false
                            },
                            data: [
                                {value: detail.per, name: ''},
                                {value: (100 - detail.per), name: ''},

                            ],

                        }
                    ]
                };
                birth.setOption(option);
            },
            //获取用户基本信息
            get_user() {
                let that = this
                let uid = localStorage.getItem("userInfo")
                request({
                    url: "/hospital/News/userInfo",
                    data: {
                        uid: uid
                    },
                }).then((ret) => {
                    if (ret.code == 1) {
                        that.user_info = ret.data.user
                        that.user_device = ret.data.device
                        console.log(ret.data.user)
                    }
                });
            },
            //获取用户健康画像
            userCenter() {
                let that = this
                let uid = localStorage.getItem("userInfo")
                request({
                    url: "/hospital/News/userCenter",
                    data: {
                        uid: uid
                    },
                }).then((ret) => {
                    if (ret.code == 1) {
                        that.realtime_info = ret.data
                    }
                });
            },
            //右侧数据
            userRight() {
                let that = this
                let uid = localStorage.getItem("userInfo")
                request({
                    url: "/hospital/News/userRight",
                    data: {
                        uid: uid,
                        hp: 1000
                    },
                }).then((ret) => {
                    if (ret.code == 1) {
                        that.user_weight = ret.data
                        that.category('tz', '', ret.data.dt, ret.data.weight, '体重/KG')
                    }
                });
            },
            userRight2() {
                let that = this
                let uid = localStorage.getItem("userInfo")
                request({
                    url: "/hospital/News/userRight2",
                    data: {
                        uid: uid,
                        hp: 1000
                    },
                }).then((ret) => {
                    let data = ret.data
                    that.userRight2Data = data
                    console.log(data.sport.list)
                    that.bar("sport_col", '1', '', data.sport.list);
                    that.pie('sport', '-', data.sport, ['#79F7F1', '#333333'])
                    that.pie2('shuimian', '-', data.sleep, ['#FEB433', '#333333'])
                });
            }

        }

    };
</script>
